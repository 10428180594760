import { Container } from "@material-ui/core"
import Error from "next/error"

const NotFound = () => (
  <Container>
    <Error statusCode={404} />
  </Container>
)

export default NotFound
